export const useLocalStorage = () => {

    const setItem = (key:string , value: unknown) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value))
        } catch (error) {
            console.log(error)
        }
    }

    const getItem = (key:string) => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : undefined;
        } catch (error) {
            console.log(error)
        }
    }

    const removeItem = (key:string) => {
        try {
            window.localStorage.removeItem(key)
        } catch (error) {
            console.log(error)
        }
    }

    const clearItem = () => {
        try {
            window.localStorage.clear()
        } catch (error) {
            console.log(error)
        }
    }

    return { setItem, getItem, removeItem, clearItem }
}
